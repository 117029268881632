import { getSpacing } from "../utils/get-spacing";
import styles from "./css/AmpContainer.module.scss";
import { IAmpContainerProps } from "./types/AmpContainerTypes";

export const AmpContainer = ({
	children,
	direction = "column",
	alignItems = "flex-start",
	justifyContent = "flex-start",
	wrap = "nowrap",
	gap = 0,
	padding,
	paddingBottom,
	paddingLeft,
	paddingRight,
	paddingTop,
	height,
	width = "full",
	flex,
	flexGrow,
	flexShrink,
	color,
	overflow,
	position,
	positionLocationSpacing,
	// Grid props
	grid = false,
	item = false,
	xs,
	sm,
	md,
	lg,
	xl,
}: IAmpContainerProps) => {
	const getClassName = () => {
		const _xs = xs ?? (item ? 12 : undefined);

		const classes = [
			styles["amp-container"],
			styles[`direction-${direction}`],
			styles[`align-${alignItems}`],
			styles[`justify-${justifyContent}`],
			styles[`wrap-${wrap}`],
			styles[`width-${width}`],
			styles[`height-${height}`],
			styles[`flex-${flex}`],
			styles[`flex-grow-${flexGrow}`],
			styles[`flex-shrink-${flexShrink}`],
			styles[`container-color-${color}`],
			styles[`container-overflow-${overflow}`],
			styles[`container-position-${position}`],
			styles[
				`container-position-${positionLocationSpacing?.location}-${positionLocationSpacing?.spacing}`
			],
			// Grid classes
			grid && styles["is-grid"],
			_xs !== undefined && styles[`grid-xs-${_xs}`],
			sm !== undefined && styles[`grid-sm-${sm}`],
			md !== undefined && styles[`grid-md-${md}`],
			lg !== undefined && styles[`grid-lg-${lg}`],
			xl !== undefined && styles[`grid-xl-${xl}`],
		];

		return classes.filter(Boolean).join(" ");
	};

	const style: React.CSSProperties = {
		gap: getSpacing(gap) ?? undefined,
		paddingTop: getSpacing(paddingTop) ?? getSpacing(padding) ?? undefined,
		paddingBottom:
			getSpacing(paddingBottom) ?? getSpacing(padding) ?? undefined,
		paddingLeft: getSpacing(paddingLeft) ?? getSpacing(padding) ?? undefined,
		paddingRight: getSpacing(paddingRight) ?? getSpacing(padding) ?? undefined,
	};

	return (
		<div className={getClassName()} style={style}>
			{children}
		</div>
	);
};
