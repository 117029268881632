import { useCallback, useEffect, useState } from "react";
import { AmpBottomSheet } from "src/components/amp-bottom-sheet/AmpBottomSheet";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpTextInput } from "src/components/ui-core/inputs/AmpTextInput";
import { useValidatePromoCode } from "../hooks/useValidatePromoCode";
import { useMembershipPurchaseContext } from "src/sites/membership-purchase/contexts/MembershipPurchaseContext";
import { EPromoCodeValidationStatus, IPromotionCode } from "amp";
import { AmpButton } from "src/components/ui-core/buttons/AmpButton";

export interface IPromoCodeModalProps {
	isOpen: boolean;
	onClose: () => void;
	onValidPromoCodeSubmitted: (promoCode: IPromotionCode) => void;
	invoiceSubtotal: number;
	priceId: number;
}

export const PromoCodeModal = ({
	isOpen,
	onClose,
	onValidPromoCodeSubmitted,
	invoiceSubtotal,
	priceId,
}: IPromoCodeModalProps) => {
	const [promoCode, setPromoCode] = useState<string>("");
	const [error, setError] = useState<string>("");
	const { userData, initialCustomerPhoneNumber } =
		useMembershipPurchaseContext();

	const onModalClose = useCallback(() => {
		onClose();
		setPromoCode("");
		setError("");
	}, [onClose]);

	const validatePromoCode = useValidatePromoCode();

	const triggerValidation = useCallback(async () => {
		if (!userData) return;
		const validation = await validatePromoCode(
			promoCode.toUpperCase(),
			userData.userId,
			userData.phoneNumber ?? initialCustomerPhoneNumber,
			invoiceSubtotal,
			priceId,
		);
		if (validation.status === EPromoCodeValidationStatus.Valid) {
			onValidPromoCodeSubmitted(validation.promoCode);
			onModalClose();
		} else {
			setError("The promo code is invalid. Please try a different code.");
		}
	}, [
		promoCode,
		userData,
		initialCustomerPhoneNumber,
		invoiceSubtotal,
		priceId,
		validatePromoCode,
		onValidPromoCodeSubmitted,
		onModalClose,
	]);

	useEffect(() => {
		if (Boolean(error) && promoCode.length === 0) {
			setError("");
		}
	}, [promoCode, setError, error]);

	return (
		<AmpBottomSheet isOpen={isOpen} onClose={onModalClose}>
			<AmpContainer width="full" padding={4} gap={4}>
				<AmpTextInput
					fullWidth
					size="small"
					variant="outlined"
					name=""
					type="text"
					label="Promo Code"
					value={promoCode}
					onChange={(e) => {
						setPromoCode(e.target.value.toUpperCase());
					}}
					error={Boolean(error)}
					helperText={Boolean(error) && error}
				/>
				<AmpButton
					label="Submit"
					onClick={triggerValidation}
					disabled={promoCode.length === 0}
					colorType="secondary"
					width="wide"
				/>
			</AmpContainer>
		</AmpBottomSheet>
	);
};
