import { Button } from "@material-ui/core";
import {
	IAmpButtonProps,
	XAmpButtonSize,
	XButtonColorType,
	XButtonType,
} from "./types/AmpButtonTypes";
import styles from "./css/AmpButton.module.scss";
import { AmpContainer } from "../containers/AmpContainer";
import { AmpText } from "../typography/AmpText";
import { XFontSize } from "../typography/types/AmpTextTypes";
import { AmpSpacer } from "../spacers/AmpSpacer";
import { XLoadingSpinnerColorType } from "../loaders/types/AmpLoadingSpinnerTypes";
import { AmpLoadingSpinner } from "../loaders/AmpLoadingSpinner";
import { XIconColorType, XIconSize } from "../icons/types/AmpIconTypes";
import { AmpIcon } from "../icons/AmpIcon";

const getAmpTextType = (size: XAmpButtonSize): XFontSize => {
	switch (size) {
		case "xxl":
			return "button_xxl";
		case "xl":
			return "button_xl";
		case "lg":
			return "button_lg";
		case "md":
			return "button_md";
		case "sm":
			return "button_sm";
		default:
			return "button_md";
	}
};

const getLoadingSpinnerColor = (
	colorType: XButtonColorType,
	type: XButtonType,
): XLoadingSpinnerColorType => {
	switch (type) {
		case "filled":
			return "white";
		case "outlined":
			return colorType;
		case "text":
			return colorType;
		default:
			return "primary";
	}
};

const getIconSize = (size: XAmpButtonSize): XIconSize => {
	switch (size) {
		case "sm":
			return "sm";
		case "md":
		case "lg":
			return "md";
		case "xl":
		case "xxl":
			return "lg";
		default:
			return "md";
	}
};

const getIconColor = (
	colorType: XButtonColorType,
	type: XButtonType,
): XIconColorType => {
	switch (colorType) {
		case "primary":
			switch (type) {
				case "filled":
					return "white";
				case "outlined":
				case "text":
					return "primary";
				default:
					return "primary";
			}
		case "secondary":
			switch (type) {
				case "filled":
					return "white";
				case "outlined":
				case "text":
					return "secondary";
				default:
					return "secondary";
			}
		default:
			return "primary";
	}
};

export const AmpButton = ({
	disabled = false,
	label,
	loading,
	colorType = "primary",
	width = "normal",
	type = "filled",
	size = "md",
	iconLeft,
	iconRight,
	submit = false,
	...props
}: IAmpButtonProps) => {
	const getClassName = () => {
		const classes = [
			styles["amp-button"],
			styles[`width-${width}`],
			styles[`color-${colorType}-${type}`],
			styles[`size-${size}`],
		];

		return classes.filter(Boolean).join(" ");
	};

	return (
		<Button
			{...props}
			disabled={disabled}
			className={getClassName()}
			classes={{
				label: styles["amp-button-label"],
			}}
			type={submit ? "submit" : "button"}
		>
			<AmpContainer
				alignItems="center"
				justifyContent="center"
				gap={2}
				direction="row"
				width="auto"
			>
				{loading && <AmpSpacer width={4} />}
				{iconLeft && (
					<AmpIcon
						icon={iconLeft}
						size={getIconSize(size)}
						color={getIconColor(colorType, type)}
					/>
				)}
				<AmpText type={getAmpTextType(size)} fontWeight="bold">
					{label}
				</AmpText>
				{iconRight && (
					<AmpIcon
						icon={iconRight}
						size={getIconSize(size)}
						color={getIconColor(colorType, type)}
					/>
				)}
				{loading && (
					<AmpLoadingSpinner
						size="sm"
						color={getLoadingSpinnerColor(colorType, type)}
					/>
				)}
			</AmpContainer>
		</Button>
	);
};
