import React, { 
	useCallback,
	useEffect,
	FC,
	PropsWithChildren,
	useState,
	useMemo
} from "react";
import {
	IPrepaidWashPromotionConfig,
	IPrepaidWashPromotion,
	XPrepaidPromotionWithDetail,
} from "amp";
import { DynamicSql } from "amp";

import { useUserZipCode } from "../hooks/useUserZipCode";
import { initializeContext } from "../../../utils/initialize-context";
import { useFetchDataDynamic } from "../../../hooks/useFetchDataDynamic";
import { LocationWithDistance, useUserZipLocation } from "../../../hooks/useUserZipLocation";
import { useBrandingContext } from "../../../contexts/BrandingContext";

const _isExpired = (promotion?: XPrepaidPromotionWithDetail<IPrepaidWashPromotion>) => {
	if(!promotion) return false;
	const now = new Date();
	const effectiveTo = promotion.prepaidPromotionDetail?.effective_to;
	const effectiveFrom = promotion.prepaidPromotionDetail?.effective_from;
	if ((effectiveFrom && now < new Date(effectiveFrom)) || (effectiveTo && now > new Date(effectiveTo))) {
		return true;
	}
	return false;
}

const _PREPAID_WASH_CONFIG_DYNAMIC_SQL = new DynamicSql(
	"prepaidWashPromotionConfig",
);

interface IPrepaidWashesContext {
	loading: boolean;
	configuration: IPrepaidWashPromotionConfig | null;
	selectedPromotion: XPrepaidPromotionWithDetail<IPrepaidWashPromotion> | undefined;
	priceGroupId: number | undefined;
	zipCode: string;
	prepaidWashName: string;
	prepaidWashNamePlural: string;
	closestLocation: LocationWithDistance | undefined;
	isPromotionExpired: boolean;

	setZipCode: React.Dispatch<React.SetStateAction<string>>;
	setSelectedPromotion: (promotion: XPrepaidPromotionWithDetail<IPrepaidWashPromotion> | undefined) => void;
	setPriceGroupId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const [
	PrepaidWashesContext,
	usePrepaidWashesContext,
	PrepaidWashesContextProvider,
] = initializeContext<IPrepaidWashesContext>("Prepaid Washes");

export const PrepaidWashPromotionsProvider: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	const [selectedPromotion, setSelectedPromotion] = useState<
		XPrepaidPromotionWithDetail<IPrepaidWashPromotion> | undefined
	>(undefined);
	const [priceGroupId, setPriceGroupId] = useState<number | undefined>(
		undefined,
	);
	const [closestLocation, setClosestLocation] = useState<LocationWithDistance | undefined>(
		undefined,
	);
	// get configurations
	const {
		configurableTextsKeyed: { PREPAID_WASHES_NAME, PREPAID_WASHES_NAME_PLURAL },
	} = useBrandingContext();
	const { data: config, loading: loadingConfiguration } = useFetchDataDynamic(
		_PREPAID_WASH_CONFIG_DYNAMIC_SQL,
	);
	const configuration: IPrepaidWashPromotionConfig | null = config
		? config[0]
		: null;

	const { getClosestLocation } = useUserZipLocation();
	const { zipCode, setZipCode } = useUserZipCode(configuration?.default_zip_code);

	const _findPriceGroupAndClosestLocation = useCallback(async () => {
		if (zipCode) {
			const closestLocation = await getClosestLocation(zipCode);
			setClosestLocation(closestLocation);
			if (closestLocation?.price_group_id__c) {
				const closestPriceGroup = closestLocation.price_group_id__c;
				setPriceGroupId(closestPriceGroup);
			} else {
				setPriceGroupId(-1);
			}
		}
	}, [zipCode]);

	const isPromotionExpired = useMemo(() => {
		return _isExpired(selectedPromotion);
	}, [selectedPromotion]);

	// define loading state
	const loadingApp = loadingConfiguration;

	useEffect(() => {
		_findPriceGroupAndClosestLocation();
	}, [_findPriceGroupAndClosestLocation]);

	return (
		<PrepaidWashesContextProvider
			value={{
				loading: loadingApp,
				configuration,
				selectedPromotion,
				priceGroupId,
				setSelectedPromotion,
				setPriceGroupId,
				zipCode,
				setZipCode,
				closestLocation,
				isPromotionExpired,
				prepaidWashName: PREPAID_WASHES_NAME ?? "Prepaid Wash",
				prepaidWashNamePlural: PREPAID_WASHES_NAME_PLURAL ?? "Prepaid Washes",
			}}
		>
			{children}
		</PrepaidWashesContextProvider>
	);
};
