import styles from "./css/AmpIcon.module.scss";
import { IAmpIconProps } from "./types/AmpIconTypes";
import * as Icons from "@material-ui/icons";

export const AmpIcon = ({
    icon,
    size = "md",
    color = "primary",
}: IAmpIconProps) => {
    const IconComponent = Icons[icon];

    const getClassName = () => {
        const classes = [
            styles["amp-icon"],
            styles[`size-${size}`],
            styles[`color-${color}`],
        ];

        return classes.filter(Boolean).join(" ");
    };

    return (
        <span className={getClassName()}>
            <IconComponent />
        </span>
    );
};